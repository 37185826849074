<div class="dialog-wrapper">
    <div mat-dialog-title>
      <div class="title">{{data?.title}}</div>
      <button class="close" (click)="close()" mat-icon-button>
        <i class="material-icons">close</i>
      </button>
    </div>
    <mat-dialog-content class="mat-typography" [ngClass]="data?.contentClass">
      <ng-template [ngTemplateOutlet]="data?.content"></ng-template>
    </mat-dialog-content>
    <ng-container *ngIf="data?.footer">
      <mat-dialog-actions [ngClass]="data?.footerClass">
        <ng-template [ngTemplateOutlet]="data?.footer"></ng-template>
      </mat-dialog-actions>
    </ng-container>
</div>