import { Component, HostListener, OnInit } from '@angular/core';
import { ICLientData } from './shared/models/client.model';
import { UtilService } from './shared/services/util/util.service';
import { ApiService } from './shared/services/api/api.service';
import { environment } from 'src/environments/environment';
import { AuthService } from './shared/services/auth/auth.service';
import { filter, map, mergeMap } from 'rxjs';
import { NavigationEnd } from '@angular/router';
import { ScriptLoaderService } from './shared/services/script-loader/script-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  params: any;
  removeMainLayoutClass = false;
  constructor(
    private api: ApiService,
    private auth: AuthService,
    private script: ScriptLoaderService,
    private util: UtilService
  ) { }

  ngOnInit() {
    if (this.util.is_browser) {
      this.params = this.util.getUrlParams();
      console.log(window.location.host);
      if (window.location.host.match('ketto')) {
        this.util.vars.useKettoLogin = true;
        this.util.vars.u_auth = this.util.cookie.getCookie('u_auth') || '';
      }
      this.setDeviceType();
      this.getClientIP();
      this.routeChangeListner();
    }
  }

  @HostListener('window:load', [])
  onWindowLoads() {
    if (this.util.is_browser) {
      this.script.loadScript('clevertap', '', true);
    }
  }

  getClientIP() {
    const ip = this.util.storage.getFromSession('iplocation');
    if (!ip) {
      this.api.get(environment.APP.GET_IP).subscribe({
        next: (res: any) => {
          this.setClientIP(res.data);
        },
        error: () => this.setClientIP(this.util.vars.default_ip_location)
      });
    } else {
      this.setClientIP(ip);
    }
  }

  setClientIP(ip: ICLientData) {
    this.util.vars.user_location.next(ip);
    this.util.storage.checkFromSession('iplocation', ip);
    this.setCurrency(ip);
  }

  setCurrency(ip: ICLientData) {
    const currencyFromUrl = this.util.actRoute.snapshot.queryParams.selected_currency;
    const currencyFromCode = this.util.getCurrencyFromCode(ip.country_code);
    const currencyFromSession = this.util.storage.getFromSession('currency');
    if (currencyFromUrl) {
      this.util.setCurrency(currencyFromUrl);
    } else if (currencyFromSession) {
      this.util.setCurrency(currencyFromSession);
    } else if (currencyFromCode) {
      this.util.setCurrency(currencyFromCode.currency);
    }
  }

  setDeviceType() {
    this.util.vars.device_type = this.util.is_mobile ? 'mobile' : 'desktop';
    const platform = this.util.cookie.getCookie('platform');
    if (platform) {
      this.util.vars.device_type = platform;
    }
    if (this.params?.platform) {
      this.util.storage.addSessionData('platform', this.params?.platform);
    }
    const sesPlatform = this.util.storage.getFromSession('platform');
    if (sesPlatform) {
      this.util.vars.device_type = sesPlatform;
    }
  }

  routeChangeListner() {
    this.util.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.util.actRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe((event) => {
      this.setUser(event?.updated_user);
      this.util.vars.page_name = event?.page_name || '';
      this.removeMainLayoutClass = event.name === 'k2_fos_signup';
    });
  }

  async setUser(latest = true) {
    let user = this.util.storage.getProperty(`${this.util.vars.user_key}`, 'user');
    if (!user && this.util.vars.u_auth) {
      this.util.storage.check(`${this.util.vars.user_key}`, { token: this.util.vars.u_auth });
      user = await this.auth.getUpdatedUserData();
      this.util.vars.u_auth = '';
      latest = false;
    }
    if (user) {
      if (latest) {
        user = await this.auth.getUpdatedUserData();
      } else {
        this.util.vars.user_data.next(this.util.storage.get(`${this.util.vars.user_data_key}`));
      }
      if (user?.isLoggedIn) {
        this.util.vars.is_logged_in.next(user.isLoggedIn);
      }
      const domain = environment.name === 'local' ? 'localhost' : '.ketto.org';
      this.util.cookie.setCookie('is_logged_in', 'true', 365, domain);
    }
  }

}
