import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth/auth.guard';

const routes: Routes = [
  {
    path: 'crowdfunding',
    loadChildren: () => import('./routes/crowdfunding').then(r => r.routes),
  },
  {
    path: 'add-money',
    loadChildren: () => import('./routes/add-money').then(r => r.routes),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./routes/dashboard').then(r => r.routes),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./routes/login').then(r => r.routes),
    canActivate: [AuthGuard]
  },
  {
    path: 'payment-route',
    loadChildren: () => import('./routes/payment-route').then(r => r.routes),
    canActivate: [AuthGuard]
  },
  {
    path: 'thankyou',
    loadChildren: () => import('./routes/thankyou').then(r => r.routes)
  },
  {
    path: 'payout-thankyou',
    loadChildren: () => import('./routes/payout-thankyou').then(r => r.routes),
    canActivate: [AuthGuard]
  },
  {
    path: 'paybill',
    loadChildren: () => import('./routes/pay-bill').then(r => r.routes),
    canActivate: [AuthGuard]
  },
  {
    path: 'request-money',
    loadChildren: () => import('./routes/request-money').then(r => r.routes),
    canActivate: [AuthGuard]
  },
  {
    path: 'kyc',
    loadChildren: () => import('./routes/kyc').then(r => r.routes),
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./routes/edit-profile').then(r => r.routes)
  },
  {
    path: 'ineligible',
    loadChildren: () => import('./routes/ineligible').then(r => r.routes),
  },
  {
    path: '',
    loadChildren: () => import('./routes/home').then(r => r.routes)
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
